import * as React from "react";

import MainLayout from "../components/MainLayout/MainLayout";

const NotFoundPage = () => (
	<MainLayout lang="hu" title="404">
		<h1>404</h1>
		<p>A keresett oldal nem található.</p>
	</MainLayout>
);

export default NotFoundPage;
